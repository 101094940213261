<template>
    <div class="p-c-page pc">
        <div class="page-content">
            <!-- 左侧分类树 -->
            <div class="p-categories">
                <div class="title">{{$t('layout.productsCenter')}}</div>
                <el-tree class="category-tree" :data="categories" default-expand-all
                         :default-checked-keys="[productParam.typeId]"
                         @node-click="onTypeClick"></el-tree>
            </div>

            <!-- 右侧产品列表 -->
            <div class="p-products">
                <!-- 面包屑导航 -->
                <el-breadcrumb>
                    <el-breadcrumb-item>
                        <router-link to="/">{{$t('layout.homePage')}}</router-link>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('layout.productsCenter')}}</el-breadcrumb-item>
                </el-breadcrumb>

                <!-- 产品列表 每页九条，3*3 -->
                <div class="product-table">
                    <router-link v-for="p in products" v-bind:key="p.itemId" class="product"
                                 :to="`/product/product-detail?productId=${p.itemId}`">
                        <!-- 保持240*320宽高比 -->
                        <aspect-ratio class="image" ratio="125">
                            <img v-bind:src="$resources.autoTransform(p.coverImgUrl)"  v-animate.hover="'zoom-big'"/>
                        </aspect-ratio>
                        <div class="name">
                            {{$i18n.locale === 'zh-CN' ? p.zhName : p.enName}}
                        </div>
                    </router-link>
                    <div v-if="products.length === 0 && $i18n.locale === 'zh-CN'" class="no-product">
                        没有符合查询条件的产品，点击<a href="/product/product-center">此处</a>查看全部产品。
                    </div>
                    <div v-if="products.length === 0 && $i18n.locale === 'en-US'" class="no-product">
                        No products found，click <a href="/product/product-center">hear</a> to view all products.
                    </div>
                </div>

                <!-- 分页 -->
                <el-pagination background layout="prev, pager, next" :total="productsPagination.total"
                               @current-change="currentPageChange"
                               @prev-click="currentPageChange(productsPagination.current-1)"
                               @next-click="currentPageChange(productsPagination.current+1)">
                </el-pagination>
            </div>
        </div>
    </div>
</template>


<script>

    import AspectRatio from '../../components/AspectRatio';

    export default {
      components: {
        'aspect-ratio': AspectRatio,
      },

      data() {
        return {
          categories: [],

          products: [],
          productParam: {},
          productsPagination: {
            searchCount: true,
            size: 9,
            current: 1,
            total: 1,
          },
        };
      },

      created() {
        window.page = this;
        // 查询参数，二级分类ID
        const typeId = this.$route.query.typeId;
        if (typeId != null) {
          this.productParam.typeId = typeId;
        }

        // 查询参数，关键字
        const keyword = this.$route.query.keyword;
        if (keyword != null) {
          this.productParam.keyword = keyword;
        }

        const loading = this.$loading();
        Promise.all([
          this.loadCategories(),
          this.loadProducts(),
        ]).then(() => loading.close());
      },

      watch: {
        $route(newValue, oldValue) {
          if (this.productParam.keyword !== newValue.query.keyword) {
            this.productParam.keyword = newValue.query.keyword;
            this.loadProducts();
          }
        }
      },

      methods: {
        loadCategories() {
          return this.$ajax.get('/categories/types').then(response => {
            if (response.code !== 100) {
              return;
            }

            const types = response.data;
            const categories = [];
            categories.push({
              id: 'all',
              label: this.$t('products.allProduct'),
              children: [],
            });
            types.forEach(t => {
              const categoryName = this.$i18n.locale === 'zh-CN' ? t.zhCategoryName : t.enCategoryName;
              let c = categories.find(c => c.id === categoryName);
              if (!c) {
                c = {
                  id: categoryName,
                  label: categoryName,
                  children: [],
                };
                categories.push(c);
              }

              c.children.push({
                id: t.typeId,
                label: this.$i18n.locale === 'zh-CN' ? t.zhName : t.enName,
              });
            });

            this.categories = categories;
          });
        },

        loadProducts() {
          const param = JSON.parse(JSON.stringify(this.productParam));
          param.size = this.productsPagination.size;
          param.current = this.productsPagination.current;
          param.searchCount = this.productsPagination.searchCount;
          return this.$ajax.get('/item', {params: this.productParam}).then(response => {
            if (response.code !== 100) {
              return;
            }

            if (this.productsPagination.searchCount) {
              this.productsPagination.total = response.data.total;
              this.productsPagination.searchCount = false;
            }

            this.products = response.data.records;
          });
        },

        onTypeClick(node) {
          // 非叶子节点点击不处理
          if (node.children && node.children.length > 0) {
            return;
          }

          if (node.id === 'all') {
            this.$router.push({
              path: this.$route.path,
            });
            this.productParam.typeId = null;
          } else {
            this.$router.push({
              path: this.$route.path,
              query: {
                typeId: node.id,
              }
            });
            this.productParam.typeId = node.id;
          }
          const loading = this.$loading();
          this.loadProducts().then(() => loading.close());
        },

        currentPageChange(page) {
          this.productsPagination.current = page;
          const loading = this.$loading();
          this.loadProducts().then(() => loading.close());
        },

      }

    }
</script>



<style lang="scss">
    .p-c-page {
        background-color: var(--white);

        .page-content {
            width: 100%;
            max-width: 1128px;
            margin: 0 auto;

            display: flex;
            justify-content: center;

            padding-top: 100px;
            padding-bottom: 200px;

            .p-categories {
                min-width: 300px;
                padding-left: 50px;

                .title {
                    font-size: 34px;
                    font-weight: 700;
                    color: var(--black);
                }
                .category-tree {
                    margin-top: 30px;
                    overflow: auto;

                    .el-tree-node:focus {
                        .el-tree-node__content {
                            color: var(--white);
                            background-color: rgb(179, 216, 255);
                        }
                    }

                    .el-tree-node__content {
                        height: 40px;
                        .el-tree-node__label {
                            font-size: 16px;
                        }
                    }
                    .el-tree-node__content:hover {
                        color: var(--white);
                        background-color: #409EFF;
                    }
                }
            }

            .p-products {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;

                flex: 1;
                max-width: 800px;
                margin-left: 35px;

                .el-breadcrumb {
                    height: 48px;
                    margin-left: 15px;
                    line-height: 60px;
                }

                .product-table {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-top: 15px;

                    .product {
                        width: 33.3333%;
                        padding: 15px 20px;
                        position: relative;
                        cursor: pointer;

                        .image {
                            width: 100%;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                                -webkit-transition: all .5s;
                                -moz-transition: all .5s;
                                -ms-transition: all .5s;
                                -o-transition: all .5s;
                                transition: all .5s;
                            }
                        }

                        .name {
                            height: 50px;
                            line-height: 50px;
                            font-size: 16px;
                            text-align: center;
                            color: var(--black);
                            background-color: rgb(242,242,242);

                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            position: absolute;
                            bottom: 15px;
                            left: 20px;
                            right: 20px;
                        }

                        .mask {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            font-size: 16px;
                            color: var(--white);
                            background-color: rgba(1,1,1, .4);

                            opacity: 0;
                            -webkit-transition: all linear .3s;
                            -moz-transition: all linear .3s;
                            -ms-transition: all linear .3s;
                            -o-transition: all linear .3s;
                            transition: all linear .3s;

                            position: absolute;
                            top: 15px;
                            right: 20px;
                            bottom: 15px;
                            left: 20px;
                            z-index: 10;
                        }
                        &:hover .mask {
                            opacity: 1;
                        }
                    }

                    .no-product {
                        width: 100%;
                        line-height: 100px;
                        font-size: 20px;
                        text-align: center;

                        a {
                            color: #409EFF;
                        }
                        a:hover {
                            color: red;
                        }
                    }
                }

                .el-pagination {
                    margin-top: 50px;
                    text-align: center;
                }
            }
        }
    }
</style>
